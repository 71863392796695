.FollowerCard {
  background-color: var(--aloeGreen);
  border-radius: 30px;
  padding: 7px 0px;
  white-space: pre-wrap;
  font-size: 14px;
  color: white;
  margin-bottom: 15px;
}

.FollowerCard-name:hover {
  color: var(--budGreen);
}
