@media (max-width: 479px) {
  .GlanceCard-container {
    background-color: white;
    border-radius: 13px;
    padding: 10px;
    margin-bottom: 20px;
    flex-shrink: 0;
  }

  .GlanceCard-sm {
    width: 145px;
    height: 71px;
  }

  .GlanceCard-lg {
    width: 305px;
    height: 71px;
  }
}

@media (min-width: 479px) {
  .GlanceCard-container {
    background-color: white;
    border-radius: 13px;
    padding: 10px;
    margin: 6px 0px;
  }

  .GlanceCard-sm {
    width: 148px;
    height: 71px;
  }

  .GlanceCard-lg {
    width: 305px;
    height: 71px;
  }
}

.GlanceCard-textContainerLg {
  flex: 2;
}

.GlanceCard-label {
  color: var(--black);
  font-size: 14px;
}

.GlanceCard-stat {
  font-size: 18px;
}

.GlanceCard-addMoreText {
  font-size: 12px;
}
