:root {
  /* Reviews */
  --darkerBrown: #8d6d68;
  --chocoBrown: #ab918d;
  --medBrown: #a0827e;
  --evenDarkerBrown: #755a57;

  /* Stats */
  --superDarkBrown: #666666;

  /* Accents */
  --aloeGreen: #99c692;
  --budGreen: #75b26c;
  --appBlue: #93d4ef;
  --columbiaBlue: #c9eaf7;

  /* Text */
  --black: #50555c;

  /* At a Glance */
  --peach: #feddbc;
}

body,
html {
  font-family: "Poppins", sans-serif;
  overflow-y: hidden;
  overflow-x: hidden;
}

.text-normal {
  font-weight: 400;
}

.text-medium {
  font-weight: 500;
}

.text-bold {
  font-weight: 700;
}

.clearfix:after {
  content: " "; /* Older browser do not support empty content */
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
