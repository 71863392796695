.Feed-page {
  padding-top: 15px;
  overflow-y: hidden;
  flex: 1;
  flex-shrink: 0;
  z-index: 1;
}

.Feed-container {
  background-color: var(--appBlue);
  width: 338px;
  border-radius: 13px;
  flex: 3;
  margin-bottom: 20px;
  overflow-y: scroll;
  padding: 15px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  z-index: 1;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.Feed-container::-webkit-scrollbar {
  display: none;
}

.Feed-container {
  -ms-overflow-style: none;
}

@media (max-width: 479px) {
  .Feed-sectionContainer {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin-top: 20px;
  }
}

@media (min-width: 479px) {
  .Feed-sectionContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 20px;
  }
}

.Feed-containerHeader {
  font-size: 20px;
  margin-bottom: 20px;
  color: white;
}

@media (max-width: 350px) {
  .Feed-title {
    font-size: 36px;
    color: var(--appBlue);
    margin-left: 5%;
  }
}

@media (min-width: 350px) {
  .Feed-title {
    font-size: 36px;
    color: var(--appBlue);
  }
}

.Feed-reviewsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-x: hidden;
  flex-shrink: 0;
}

.Feed-search {
  width: 338px;
  margin-bottom: 16px;
}

.Feed-tabs {
  width: 338px;
  margin-bottom: 20px;
}
