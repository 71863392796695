.ReviewMessage-message {
  background-color: white;
  min-width: 305px;
  height: 100%;
  max-height: 220px;
  border-radius: 13px;
  margin-right: 15px;
  margin-bottom: 15px;
  padding: 15px;
}

.ReviewMessage-date {
  color: var(--black);
  font-size: 10px;
}

.ReviewMessage-price {
  font-size: 18px;
  color: var(--black);
}

.ReviewMessage-drinkName {
  font-size: 17px;
  color: var(--black);
}

.ReviewMessage-text {
  font-size: 12px;
  color: var(--black);
}

.ReviewMessage-break {
  word-wrap: break-word;
}

.ReviewMessage-userDisplayName {
  color: var(--aloeGreen);
}

.ReviewMessage-userDisplayName:hover {
  color: var(--budGreen);
}

.ReviewMessage-userPhrase {
  white-space: pre-wrap;
}
