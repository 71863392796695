@media (max-width: 479px) {
  .Reviews-container {
    background-color: var(--chocoBrown);
    width: 338px;
    border-radius: 13px;
    margin-bottom: 20px;
    overflow-y: scroll;
    padding: 15px;
    min-height: 63%;
    max-height: 63%;
  }
}

@media (min-width: 479px) {
  .Reviews-container {
    background-color: var(--chocoBrown);
    width: 338px;
    border-radius: 13px;
    margin-bottom: 20px;
    overflow-y: scroll;
    padding: 15px;
    flex: 3;
  }
}

.Reviews-container::-webkit-scrollbar {
  display: none;
}

.Reviews-container {
  -ms-overflow-style: none;
}

.Reviews-containerHeader {
  font-size: 20px;
  margin-bottom: 20px;
}

.Reviews-newReviewButton {
  border-radius: 50%;
  background-color: white;
  color: var(--appBlue);
  height: 57px;
  width: 57px;
  font-size: 50px;
  cursor: pointer;
}

.Reviews-reviewsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-y: hidden;
  height: 240px;
  overflow-x: scroll;
  scroll-behavior: smooth;
}

@media (max-width: 479px) {
  .Reviews-sectionContainer {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
  }
}

@media (min-width: 479px) {
  .Reviews-sectionContainer {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
  }
}

.Reviews-sectionContainerMargin {
  margin-top: 30px;
  flex-shrink: 0;
}

/* width */
.Reviews-reviewsContainer::-webkit-scrollbar {
  -webkit-appearance: none;
}

.Reviews-reviewsContainer::-webkit-scrollbar:horizontal {
  height: 10px;
}

/* Track */
.Reviews-reviewsContainer::-webkit-scrollbar-track {
  background: var(--medBrown);
}

/* Handle */
.Reviews-reviewsContainer::-webkit-scrollbar-thumb {
  background: var(--darkerBrown);
}

/* Handle on hover */
.Reviews-reviewsContainer::-webkit-scrollbar-thumb:hover {
  background: var(--evenDarkerBrown);
}
