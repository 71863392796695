.Follow-icon {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 31px;
  height: 31px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Follow-followIcon {
  border: 1px solid var(--appBlue);
  box-sizing: border-box;
  border-radius: 5px;
  width: 31px;
  height: 31px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--appBlue);
}
