.FriendCard {
  background-color: white;
  border-radius: 13px;
  padding: 15px;
  margin-bottom: 15px;
  flex-shrink: 0;
}

.FriendCard-username {
  color: var(--black);
  font-size: 15px;
}

.FriendCard-name {
  color: var(--black);
  font-size: 11px;
}

.FriendCard-avatar {
  height: 45px;
  border-radius: 50%;
}
