@media (max-width: 479px) {
  .Glance-container {
    background-color: var(--peach);
    width: 338px;
    border-radius: 13px;
    margin-bottom: 20px;
    overflow-y: scroll;
    padding: 15px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    min-height: 63%;
    max-height: 63%;
  }
}

@media (min-width: 479px) {
  .Glance-container {
    background-color: var(--peach);
    width: 338px;
    border-radius: 13px;
    margin-bottom: 20px;
    overflow-y: scroll;
    padding: 15px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    flex: 3;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.Glance-container::-webkit-scrollbar {
  display: none;
}

@media (max-width: 479px) {
  .Glance-containerItem {
    margin-bottom: 20px;
  }
}

.Glance-container::-webkit-scrollbar {
  display: none;
}

.Glance-container {
  -ms-overflow-style: none;
}

.Glance-header {
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--black);
}
