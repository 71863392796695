@media (max-width: 479px) {
  .SummaryItem {
    width: 250px;
    height: 250px;
    /* margin-bottom: 50px; */
    flex-shrink: 0;
  }
}

@media (min-width: 479px) {
  .SummaryItem {
    width: 250px;
    height: 250px;
    /* margin-bottom: 120px; */
    flex-shrink: 0;
  }
}

.SummaryItem-text {
  font-size: 20px;
}

.SummaryItem-largeText {
  font-size: 36px;
}

@media (max-width: 479px) {
  .SummaryItem-date {
    font-size: 30px;
    margin-bottom: 20px;
  }
}

@media (min-width: 479px) {
  .SummaryItem-date {
    font-size: 30px;
    margin-bottom: 100px;
  }
}
