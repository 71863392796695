@media (max-width: 479px) {
  .Summary-container {
    background-color: var(--superDarkBrown);
    width: 338px;
    border-radius: 13px;
    margin-bottom: 20px;
    overflow-y: scroll;
    padding: 20px;
    justify-content: space-between;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    min-height: 63%;
    max-height: 63%;
  }
}

@media (min-width: 479px) {
  .Summary-container {
    background-color: var(--superDarkBrown);
    width: 338px;
    border-radius: 13px;
    margin-bottom: 20px;
    overflow-y: scroll;
    padding: 20px;
    justify-content: space-between;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    flex: 3;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.Feed-container::-webkit-scrollbar {
  display: none;
}

.Summary-container::-webkit-scrollbar {
  display: none;
}

.Summary-container {
  -ms-overflow-style: none;
}
