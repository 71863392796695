.NotFound-page {
  background-color: white;
  overflow-y: hidden;
}

.NotFound-header {
  color: var(--appBlue);
  font-size: 36px;
  margin: 24px 0px;
}

.NotFound-text {
  font-size: 15px;
  width: 276px;
  text-align: center;
  color: var(--black);
}

.NotFound-boba {
  height: 130px;
}
