.Drawer-icon {
  right: 10px;
  top: 10px;
  position: absolute;
}

.Drawer-tags {
  height: 70%;
  padding: 0px 15%;
}
