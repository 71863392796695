.Calendar {
  background-color: white;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
}

.Calendar-table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0px 3px;
}

.Calendar-table thead {
  display: table;
  width: 100%;
}

.Calendar-day {
  padding: 4px 12px;
  color: var(--black);
  font-size: 10px;
}

.Calendar-month {
  padding: 8px 0px;
  font-size: 17px;
  color: var(--black);
}

.Calendar-reviewDayStart {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.Calendar-reviewDayEnd {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.Calendar-reviewDay {
  background-color: var(--appBlue);
  color: white;
}
