.Main-page {
  padding-top: 15px;
  overflow-y: hidden;
  flex: 1;
}

.Main-userContainer {
  height: 100%;
  margin-left: 20px;
}

.Main-name {
  font-size: 30px;
  color: var(--black);
  word-wrap: break-word;
}

.Main-username {
  font-size: 13px;
  color: var(--black);
}

.Main-header {
  font-size: 20px;
  width: 338px;
}

.Main-avatar {
  height: 94px;
  border-radius: 50%;
}

.Main-avatarNoBorder {
  height: 94px;
}

.Main-tabs {
  width: 338px;
  margin-bottom: 10px;
  flex: 0.5;
}

.Main-back {
  position: absolute;
  left: 20px;
  top: 20px;
}
