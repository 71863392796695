.Root-page {
  background-color: white;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* from https://css-tricks.com/the-trick-to-viewport-units-on-mobile/ */
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}
